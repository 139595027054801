<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in test"
      :key="msgGrp.senderId+String(index)"
      :class="{'chat-left': msgGrp.fromGeminos === false}"
    >
      <div class="chat-avatar">
        <b-badge
          pill
          variant="primary"
          style="margin-top: 10px"
        >
          {{ getSenderName(msgGrp.senderName) }}
        </b-badge>
        <!--        <b-avatar-->
        <!--          size="36"-->
        <!--          class="avatar-border-2 box-shadow-1"-->
        <!--          variant="transparent"-->
        <!--          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"-->
        <!--        />-->
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time"
          class="chat-content"
          :class="{'chat-content-received': msgData.received === true}"
        >
          <p>{{ msgData.msg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable consistent-return */
import { computed } from '@vue/composition-api'
import { BAvatar, BBadge } from 'bootstrap-vue'
import { Chat } from 'twitch-js'

export default {
  components: {
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formattedChatList: [],
      joinedChat: false,
    }
  },
  computed: {
    test() {
      return this.$store.state.chat.chatLog
    },
  },
  mounted() {
    this.connectToChat()
  },
  methods: {
    getSenderName(senderName) {
      if (senderName.length > 8) return `${senderName.slice(0, 8)}...`
    },
    async connectToChat() {
      if (this.joinedChat) return true
      const chat = new Chat({
        username: undefined,
        token: undefined,
        log: { level: 'warn' },
      })
      this.joinedChat = true

      chat.on('PRIVMSG', message => {
        // TODO Check last Messages if username = GeminosName && Message sent  then change property received -> true
        console.log(message)
        const msgGroup = {
          senderId: message.username,
          senderName: message.username,
          fromGeminos: false,
          messages: [{
            received: true,
            fromGeminos: false,
            msg: message.message,
            time: message.timestamp,
          }],
        }
        this.$store.state.chat.chatLog.push(msgGroup)
      })

      await chat.connect()
      await chat.join('mewliesdl')
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      console.log(props)
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            received: msg.received,
            msg: msg.message,
            time: msg.time,
            senderName: msg.senderName,
          })
        } else {
          chatMessageSenderId = msg.senderId
          this.formattedChatList.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            senderName: msg.senderName,
            messages: [{
              received: msg.received,
              msg: msg.message,
              time: msg.time,
            }],
          }
        }
        if (index === chatLog.length - 1) this.formattedChatList.push(msgGroup)
      })

      return {
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

<style>

</style>
