<template>
  <component
    :is="tag"
    class="d-flex align-items-center"
    v-on="$listeners"
  >
    <b-avatar
      size="30"
      :src="user.avatar"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      variant="transparent"
    />
    <div class="chat-info d-flex align-items-center">
      <b-row>
        <b-col>
          <h5 class="mb-0">
            {{ user.fullName }}
          </h5>
        </b-col>
        <b-col>
          <b-button
            variant="gradient-danger"
            class="btn-icon"
            size="sm"
          >
            <feather-icon icon="UserPlusIcon" />
          </b-button>
        </b-col>
      </b-row>

      <!--        {{ isChatContact ? user.chat.lastMessage.message : user.about }}-->
    </div>
    <!--    <div-->
    <!--      v-if="isChatContact"-->
    <!--      class="chat-meta text-nowrap"-->
    <!--    >-->
    <!--      <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }}</small>-->
    <!--&lt;!&ndash;      <b-badge&ndash;&gt;-->
    <!--&lt;!&ndash;        v-if="user.chat.unseenMsgs"&ndash;&gt;-->
    <!--&lt;!&ndash;        pill&ndash;&gt;-->
    <!--&lt;!&ndash;        variant="primary"&ndash;&gt;-->
    <!--&lt;!&ndash;        class="float-right"&ndash;&gt;-->
    <!--&lt;!&ndash;      >&ndash;&gt;-->
    <!--&lt;!&ndash;        {{ user.chat.unseenMsgs }}&ndash;&gt;-->
    <!--&lt;!&ndash;      </b-badge>&ndash;&gt;-->
    <!--    </div>-->
  </component>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BButton,
    BBadge,
    BRow,
    BCol,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
}
</script>

<style>

</style>
